.body-content {
  width: calc(100% - 70px);
  background-color: #e3edf4;
  margin-left: 70px;
  height: 100vh;
  tr {
    background: var(--mainTheme100);
    &:nth-child(even) {
      background: #fff;
      white-space: nowrap !important;
    }
  }
  &.active {
    width: 100%;
    margin-left: 0;
    .header {
      width: 100%;
    }
  }
}

//full width body style
.body {
  &.fullWidth-body {
    .body-content {
      width: 100%;
      margin-left: 0;
    }
    .header {
      width: 100%;
      margin-left: 0;
    }
  }
}
