.App {
  text-align: center;
  padding: 0;
  margin: 0;
}
iframe {
  display: none;
}
:global(.MuiRadio-color) {
  color: black;
}

.body {
  display: flex;

  .right-content {
    margin-top: 60px;
    padding: 20px;
    background: var(--mainTheme100);
  }
}

.filter-amtenties {
  .list-item {
    padding-right: 15px;
    display: flex;
  }

  .right-amentities {
    .list-item:last-child {
      padding-right: 0;
    }
  }
}

.dropdownMemberList {
  border-radius: 20px;
  background: var(--mainTheme200);
  box-shadow:
    -4px -4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border: none !important;
  outline: none !important;
  font-weight: 500;
  font-size: 30px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.member-list-left
  .css-1czf7ta-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
  text-align: start;
}

.dropdownMemberList:hover {
  border: none;
  outline: none;
  background-color: var(--mainTheme);
  color: #fff;
}

.dropdownMemberList:focus {
  border: none;
  outline: none;
  background-color: var(--mainTheme);
}

.dropdownValueMemberList {
  margin-top: 20px;
}

.member-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member-list-right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.member-list-left {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}

.filterBoxVC {
  border-radius: 20px;
  width: 154px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: var(--mainTheme200);
  box-shadow:
    -4px -4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.filterBoxVC p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.searchBoxML {
  width: 247px;
  height: 40px;
  border-radius: 50px;
  background: var(--mainTheme200);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    4px 4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 2px 8px 0px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 16px !important;
  scrollbar-gutter: stable;
  cursor: default !important;
}

::-webkit-scrollbar-thumb {
  background: #b7b6b6 !important;
  height: 5px !important;
  border-radius: 2px !important ;
  cursor: default !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #aba7a7 !important;
  cursor: default !important;
}
.MuiPickersLayout-root {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
