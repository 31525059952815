.filter-amtenties {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-amentities {
        width: 40%;
        display: flex;
        justify-content: flex-start;
    }

    .right-amentities {
        width: 60%;
        display: flex;
        justify-content: flex-end;
    }
}