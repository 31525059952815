//Author: Sowmiya
//page: calendar page style
.calendar-card {
  border-radius: 20px;
  background: var(--colorWhite);
  padding: 20px;
  box-shadow: 0px 60px 120px 0px rgba(38, 51, 77, 0.05);
}
.full-calendar {
  font-family: Inter, sans-serif;
  .fc {
    .fc-button-primary {
      height: 40px;
      vertical-align: middle;
      border-radius: 20px;
      border: 2px solid rgba(0, 0, 0, 0.3);
      background-color: var(--colorWhite);
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px; /* 166.667% */
      box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
      &.fc-button-active {
        background-color: var(--mainTheme);
      }
    }
    .fc-toolbar-title {
      font-size: 16px;
    }
  }
  tr {
    background-color: var(--colorWhite);
    border: 1px solid #cfd3db;
  }
}
