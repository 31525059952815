button {
  font-size: 16px;
  background-color: var(--mainTheme);
  color: var(--colorWhite);
  border-radius: 100px;
  border: none;
  height: 34px;
  // min-width: 80px; // Breaks the calendar, when adding props to global elements precausions must be taken(check if it breaks something else)
}
button.MuiButtonBase-root {
  min-width: 30px;
}
.custom-button {
  /* if datatable contains action buttons applied this styles */
  /* Your existing button styles */

  /* Add a custom style for the disabled state */
  &.disabled {
    pointer-events: none; /* Disable click events */
    opacity: 0.5; /* Apply a visual cue for disabled state */
    cursor: not-allowed; /* Change the cursor type */
  }
}
