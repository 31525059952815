body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --mainTheme: var(--colorBlue);
  --colorBlue: #1076bc;
  --colorDarkBlue: #285d82;
  --colorWhite: #ffffff;
  --colorBlack: #000000;
  --colorGray: #d9d9d9;
  --colorLiteblue: #e3edf4;
  --line-border-fill: var(--mainTheme);
  --line-border-empty: var(--colorWhite);
  --mainTheme50: color-mix(in srgb, var(--mainTheme) 5%, var(--colorWhite));
  --mainTheme100: color-mix(in srgb, var(--mainTheme) 10%, var(--colorWhite));
  --mainTheme150: color-mix(in srgb, var(--mainTheme) 15%, var(--colorWhite));
  --mainTheme200: color-mix(in srgb, var(--mainTheme) 20%, var(--colorWhite));
  --mainTheme250: color-mix(in srgb, var(--mainTheme) 25%, var(--colorWhite));
  --mainTheme300: color-mix(in srgb, var(--mainTheme) 30%, var(--colorWhite));
  --mainTheme350: color-mix(in srgb, var(--mainTheme) 35%, var(--colorWhite));
  --mainTheme400: color-mix(in srgb, var(--mainTheme) 40%, var(--colorWhite));
  --mainTheme450: color-mix(in srgb, var(--mainTheme) 45%, var(--colorWhite));
  --mainTheme500: color-mix(in srgb, var(--mainTheme) 50%, var(--colorWhite));
  --mainTheme550: color-mix(in srgb, var(--mainTheme) 55%, var(--colorWhite));
  --mainTheme600: color-mix(in srgb, var(--mainTheme) 60%, var(--colorWhite));
  --mainTheme650: color-mix(in srgb, var(--mainTheme) 65%, var(--colorWhite));
  --mainTheme700: color-mix(in srgb, var(--mainTheme) 70%, var(--colorWhite));
  --mainTheme750: color-mix(in srgb, var(--mainTheme) 75%, var(--colorWhite));
  --mainTheme800: color-mix(in srgb, var(--mainTheme) 80%, var(--colorWhite));
  --mainTheme850: color-mix(in srgb, var(--mainTheme) 85%, var(--colorWhite));
  --mainTheme900: color-mix(in srgb, var(--mainTheme) 90%, var(--colorWhite));
  --mainTheme950: color-mix(in srgb, var(--mainTheme) 95%, var(--colorWhite));
}
