.login {
  display: flex;
  background: #e3edf4;
  height: 100vh;
}

/* login.css */
.reset-button {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 20px; /* Add spacing if needed */
}

.image-container,
.input-container {
  width: 50%;
  height: 100%;
  overflow: hidden;
}
.errorStyle {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: -34px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 15px;
  padding: 8px;
}

.password {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: -42px;
  margin-right: 0;
  margin-bottom: 0;
  padding: 14px;
}

.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 6px !important;
  padding: 5px !important;
}

/* .input-container::before {
    content:'';
    position: absolute;
    width: 450px;
    height: 350px;
    background: linear-gradient(0deg, #1076BC 0%, rgba(16, 118, 188, 0.00) 100%);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
} */

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 200px;
    height: 180px;
  }
}

.top-ripple {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0 0 0 323px;
  background: linear-gradient(180deg, #1076bc 0%, rgba(16, 118, 188, 0) 100%);
  width: 200px;
  height: 180px;
  animation: animate 3s linear 1;
}

.bottom-ripple {
  position: absolute;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  border-radius: 323px 0 0 0;
  background: linear-gradient(180deg, #1076bc 0%, rgba(16, 118, 188, 0) 100%);
  width: 200px;
  height: 180px;
  animation: animate 3s linear 1;
}

.input-container .Mui-error {
  line-height: 14px !important;
  /* padding-left: 10px; */
}

.input-container h2 {
  margin-top: 6px;
  font-weight: 500;
  /* text-transform: uppercase; */
  line-height: normal;
  font-size: 23px;
  font-style: "Inter", sans-serif;
  margin-bottom: 30px;
}

.bgImg {
  width: 50vw;
  height: 100vh;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.logoImg {
  width: 220px;
  height: 60px;
  animation: logoAnimate 3s linear 1;
}

.formControlLabel {
  margin-right: "30px";
  margin-left: "0px";
  font-size: 16px;
}

.button-containers button:hover {
  color: #0000ee !important;
  text-decoration: underline;
  cursor: pointer;
}

.submitButton {
  cursor: pointer;
}

.formControlLabel:checked {
  color: black !important;
}

@keyframes logoAnimate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* @media only screen and (max-width: 320px){
    .form{
        width:100%;
    }

    .css-v3f5ls{
        width: 100% !important;
    }
    .css-1aa5qj0-MuiInputBase-root-MuiInput-root{
        padding-left: 12px;
    }
} */

/* mobile */
@media only screen and (max-width: 480px) {
  .login {
    display: block;
    width: 100%;
    background-color: white;
    /* height: auto; */
  }

  .input-container {
    width: 100%;
    margin-top: 20px;
  }

  .image-container,
  .input-container {
    width: 100% !important;
    height: auto;
  }

  .top-ripple {
    display: none;
  }

  .bottom-ripple {
    display: none;
  }

  .bgImg {
    width: 100%;
    height: auto;
  }

  .css-1kfd4qk {
    width: auto !important;
  }
}

@media only screen and (max-width: 769px) {
  .form {
    width: 80%;
  }
  /* .css-v3f5ls{
        width: 100% !important;
    } */
  .css-ittuaa-MuiInputAdornment-root {
    padding-left: 12px;
  }
  .css-1kfd4qk {
    width: auto !important;
  }
}

/* For Tablet View */
@media screen and (min-width: 770px) and (max-width: 916px) {
  .form {
    width: 80%;
  }
  /* .css-v3f5ls{
        width: 100% !important;
    } */
  .css-ittuaa-MuiInputAdornment-root {
    padding-left: 12px;
  }
  .css-1kfd4qk {
    width: auto !important;
  }
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .form {
    width: 80%;
  }
}
