.content-memberlist {
  width: 100%;
  margin: 5px 10px;
  padding: 0 10px;
}
.right-content-qaList {
  /* margin-top: 10px; */
  padding-inline: 20px;
  padding-block: 10px;
}
.checkbox-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust the width as needed */
  flex-shrink: 0;
}

.datatable-memberlist {
  margin-top: 15px;
}

.dropdownMemberList {
  border-radius: 20px;
  background: "var(--mainTheme)" !important;
  box-shadow:
    -4px -4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border: none !important;
  outline: none !important;
  font-weight: 500;
  font-size: 30px;
  font-family: sans-serif;
}
.css-faieev-MuiTableCell-root.MuiTableCell-head {
  font-weight: 700 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.member-list-left
  .css-1czf7ta-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  padding-left: 0px !important;
  margin-left: 0px !important;
  text-align: start;
}

.dropdownMemberList:hover {
  border: none;
  outline: none;
  background-color: "var(--mainTheme)";
  color: #fff;
}

.dropdownMemberList:focus {
  border: none;
  outline: none;
  background-color: "var(--mainTheme)";
}

.member-list-left .css-q8hpuo-MuiFormControl-root {
  width: auto;
}

.member-list-right .css-q8hpuo-MuiFormControl-root {
  width: auto;
}

.dropdownValueMemberList {
  margin-top: 20px;
}

.css-sz74on-MuiList-root-MuiMenu-list {
  background-color: #d9d9d9 !important;
  color: black !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: "var(--mainTheme)" !important;
  color: black !important;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
    background-color: #399cdf !important;
} */
/* .dropdownMemberList .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    top: 143px !important;
    left: 94px !important;
    border-radius: 12px 12px 0px 0px !important;
} */

.qamember-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 75px;
}

.qamember-list-right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.qamember-list-left {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}

.filterBoxML {
  border-radius: 20px;
  width: auto;
  height: 40px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: var(--mainTheme200);
  padding: 20px;
  cursor: pointer;
  box-shadow:
    -4px -4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 4px 8px 0px rgba(0, 0, 0, 0.25);
}

.filterBoxML p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterBoxFilterML {
  border-radius: 20px;
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: var(--mainTheme200);
  box-shadow:
    -4px -4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 4px 8px 0px rgba(0, 0, 0, 0.25);
}

.filterBoxFilterML p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.searchBoxML {
  width: 247px;
  height: 40px;
  border-radius: 50px;
  background: var(--mainTheme200);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow:
    4px 4px 8px 0px rgba(255, 255, 255, 0.75),
    4px 2px 8px 0px rgba(0, 0, 0, 0.2) inset;
}

.searchBoxML:hover {
  border: 2px solid var(--mainTheme);
}
.custom-tabs {
  border-radius: 10px;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.custom-tabs .MuiTab-root {
  font-size: 13px;
  color: #333;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: -1px;
  border: 1px solid #ddd;
  border-top: none;
  background: var(--mainTheme350);
  transition: all 300ms ease-out;
  padding: 12px 10px !important;
  text-transform: capitalize;
}

.custom-tabs .MuiTab-root:hover {
  background: var(--mainTheme850);
  transform: translateY(-0.5px);
  color: #fff !important;
}

.custom-tabs .Mui-selected {
  background-color: var(--mainTheme);
  color: #fff !important;
}
